import React from 'react'
import './scss/styles.scss'
import Link from 'gatsby-link'
import { Button } from 'components'

const page = 'jobs'

class Page extends React.Component {
  t = (msg, params) => this.props.intl.formatMessage(msg, params);

  render() {
    const { lang = 'en' } = this.props
    return (
      <div>
        <div className="main">
          <div className="template">
            <section>
              <h1 className="headline">Current Openings</h1>
              <p className="text bold">Let’s make great things together</p>
              <p className="text">If your desire is to surround yourself with smart people that you
                will learn from – in an open, collaborative, transparent environment – and
                simultaneously contribute to that knowledge then you just might be what we're
                looking for:</p>
            </section>
            <section>
              <h1 className="headline left">Customer Development</h1>
              <p className="text">As a customer development representative, you spearhead Stairlin’s
                growth strategy. You know how to walk in our customers’ shoes and understand how
                they work and the problem they face. You are experienced in talking to, and moving
                between,
                customers and engineers. Most importantly you are a champion at listening to
                customer objections and understanding whether they are issues about the product, the
                presentation, or the pricing.</p>
              <ul className="skills">
                <p className="text bold">What we'll expect from you:</p>
                <li className="text list">
                  — 2+ years of relevant sales/marketing experience
                </li>
                <li className="text list">
                  — Proven B2B or B2C sales track record
                </li>
                <li className="text list">
                  — Autonomous and highly driven to achieve and exceed sales target
                </li>
                <li className="text list">
                  — Proficient with a CRM software is a plus
                </li>
                <li className="text list">
                  — Native speaker in Swiss-German, French or Italian, proficient in English
                </li>
                <li className="text list">
                  — Second Swiss or European national language is of advantage
                </li>
              </ul>
              <Button bg={'primary'} color={'white'} href="mailto:job@stairlin.com?subject=Job: Customer Development">Apply</Button>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default (Page);
